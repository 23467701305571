<template>
	<div
		v-if="title || (Array.isArray(link) && link.length)"
		:id="$vnode.key"
		class="c-block-list-cta-button-custom"
	>
		<LongReadTarget
			v-if="
				title &&
				includeInToc &&
				accordionGroup != null &&
				Array.isArray(link) &&
				link.length
			"
			:container-id="$vnode.key"
			:title="title"
		/>

		<div class="flex flex-col gap-x-md gap-y-sm">
			<NuxtLinkExt
				v-for="(item, index) in computedLinks"
				:key="`ctabutton-${index}`"
				:to="item.url"
				:target="item.type === 'media' ? '_blank' : item.target"
				:download="item.type === 'media' ? item.name : false"
				class="inline-block"
			>
				<BaseButton
					tag="div"
					:class="[
						'c-block-list-cta-button-custom__item w-full >=768:max-w-fit',

						{
							'c-block-list-cta-button-custom__item--media':
								item.type === 'media',
						},
					]"
				>
					<template #default>
						<div class="flex items-center space-x-8">
							<span class="w-full" v-text="item.name"></span>

							<!-- Labels -->
							<div
								:class="[
									'flex flex-col <768:space-y-8 flex-shrink-0 >=768:flex-row >=768:space-x-8',
									'font-darker-grotesque font-bold uppercase',
									'text-label-file mt-2 >=1024:mt-4',
								]"
							>
								<div
									v-if="item.type === 'media'"
									:class="[
										'rounded-full border-2 border-black',
										'px-8 pt-3 pb-6',
									]"
									v-text="`.${item.extension}`"
								></div>

								<div
									v-if="item.type === 'media'"
									:class="[
										'rounded-full border-2 border-black',
										'px-8 pt-3 pb-6',
									]"
									v-text="item.size"
								></div>
							</div>
						</div>
					</template>

					<template #icon>
						<SvgDownload v-if="item.type === 'media'" />
						<SvgArrow
							v-else-if="item.isExternal"
							class="transform -rotate-45"
						/>
					</template>
				</BaseButton>
			</NuxtLinkExt>
		</div>
	</div>
</template>

<script>
import LongReadTarget from '~/citi-baseline/components/LongRead/LongReadTarget';
import SvgArrow from '~/assets/svgs/icon-arrow.svg?inline';
import SvgDownload from '~/assets/svgs/icon-download.svg?inline';

export default {
	name: 'BlockListCtaButtonCustom',
	components: { LongReadTarget, SvgArrow, SvgDownload },

	inject: {
		accordionGroup: {
			default: null,
		},
	},
	inheritAttrs: false,

	props: {
		title: String,
		link: Array,
		includeInToc: Boolean,
	},

	computed: {
		computedLinks() {
			return this.link?.map((item) => ({
				...item,
				isExternal: this.isExternal(item?.url),
				size: this.formatSize(item?.size),
			}));
		},
	},

	methods: {
		isExternal(url) {
			if (url) {
				if (
					['http://', 'https://', 'ftp://'].some(
						(str) => url.indexOf(str) === 0
					)
				) {
					return true;
				}

				const splitOut = url.split('/');
				if (splitOut[0].indexOf('.') >= 0) {
					return true;
				}
			}

			return false;
		},

		formatSize(bytes) {
			if (bytes) {
				const sizes = {
					GB: Math.pow(1000, 3),
					MB: Math.pow(1000, 2),
					KB: 1000,
				};

				const keys = Object.keys(sizes);
				for (let i = 0; i < keys.length; i++) {
					const response = bytes / sizes[keys[i]];
					if (response >= 1 || i === keys.length - 1) {
						return `${response.toFixed(2)} ${keys[i]}`;
					}
				}
			}

			return false;
		},
	},
};
</script>

<style lang="postcss">
.c-block-list-cta-button-custom__item--media .c-base-button__inner {
	@apply py-4;
}
</style>
