var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.title || (Array.isArray(_vm.link) && _vm.link.length))?_c('div',{staticClass:"c-block-list-cta-button-custom",attrs:{"id":_vm.$vnode.key}},[(
			_vm.title &&
			_vm.includeInToc &&
			_vm.accordionGroup != null &&
			Array.isArray(_vm.link) &&
			_vm.link.length
		)?_c('LongReadTarget',{attrs:{"container-id":_vm.$vnode.key,"title":_vm.title}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex flex-col gap-x-md gap-y-sm"},_vm._l((_vm.computedLinks),function(item,index){return _c('NuxtLinkExt',{key:`ctabutton-${index}`,staticClass:"inline-block",attrs:{"to":item.url,"target":item.type === 'media' ? '_blank' : item.target,"download":item.type === 'media' ? item.name : false}},[_c('BaseButton',{class:[
					'c-block-list-cta-button-custom__item w-full >=768:max-w-fit',

					{
						'c-block-list-cta-button-custom__item--media':
							item.type === 'media',
					},
				],attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"flex items-center space-x-8"},[_c('span',{staticClass:"w-full",domProps:{"textContent":_vm._s(item.name)}}),_vm._v(" "),_c('div',{class:[
								'flex flex-col <768:space-y-8 flex-shrink-0 >=768:flex-row >=768:space-x-8',
								'font-darker-grotesque font-bold uppercase',
								'text-label-file mt-2 >=1024:mt-4',
							]},[(item.type === 'media')?_c('div',{class:[
									'rounded-full border-2 border-black',
									'px-8 pt-3 pb-6',
								],domProps:{"textContent":_vm._s(`.${item.extension}`)}}):_vm._e(),_vm._v(" "),(item.type === 'media')?_c('div',{class:[
									'rounded-full border-2 border-black',
									'px-8 pt-3 pb-6',
								],domProps:{"textContent":_vm._s(item.size)}}):_vm._e()])])]},proxy:true},{key:"icon",fn:function(){return [(item.type === 'media')?_c('SvgDownload'):(item.isExternal)?_c('SvgArrow',{staticClass:"transform -rotate-45"}):_vm._e()]},proxy:true}],null,true)})],1)}),1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }